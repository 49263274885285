import styled, { css } from "styled-components";
import cardBg from "../../images/card-bg.svg";
import cardBgFlipped from "../../images/card-bg-flipped.svg";
import cardBgMobile from "../../images/card-bg-mobile.svg";
import cardBgMobileFlipped from "../../images/card-bg-mobile-flipped.svg";
import productCardBg from "../../images/product-card-bg.svg";
import productCardBgFlipped from "../../images/product-card-bg-flipped.svg";
import productCardBgMobile from "../../images/product-card-bg-mobile.svg";
import productCardBgMobileFlipped from "../../images/product-card-bg-mobile-flipped.svg";

const productMobile = css`
  background: ${props =>
        props.flipped
          ? `url(${productCardBgMobileFlipped})`
          : `url(${productCardBgMobile})`}
      center bottom no-repeat,
    ${props => (props.background ? props.background : "#fff")};
`;

const productDesktop = css`
  background: ${props =>
        props.flipped
          ? `url(${productCardBgFlipped})`
          : `url(${productCardBg})`}
      center bottom no-repeat,
    ${props => (props.background ? props.background : "#fff")};
`;

const Card = styled.div`
  width: 280px;
  height: 410px;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  background: ${props =>
        props.flipped ? `url(${cardBgMobileFlipped})` : `url(${cardBgMobile})`}
      center bottom no-repeat,
    ${props =>
      props.background
        ? props.background
        : "linear-gradient(270.72deg, #0de489 -2.9%, #07a8b3 102.01%)"};
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;

  ${props => props.type === "product" && productMobile}

  @media (min-width: 970px) {
    width: 300px;
    height: 415px;

    background: ${props =>
          props.flipped ? `url(${cardBgFlipped})` : `url(${cardBg})`}
        center bottom no-repeat,
      ${props =>
        props.background
          ? props.background
          : "linear-gradient(270.72deg, #0de489 -2.9%, #07a8b3 102.01%)"};

    ${props => props.type === "product" && productDesktop}
  }

  @media (min-width: 1000px) {
    width: 305px;
    height: 420px;
  }

  @media (min-width: 1030px) {
    width: 310px;
    height: 420px;
  }

  @media (min-width: 1060px) {
    width: 320px;
    height: 420px;
  }

  @media (min-width: 1110px) {
    width: 330px;
    height: 420px;
  }

  @media (min-width: 1150px) {
    width: 350px;
    height: 455px;
  }
`;

export default Card;
