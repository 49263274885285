import styled from "styled-components";

const Cards = styled.div`
  display: flex;
  justify-content: space-between;
  overflow-x: scroll;
  overflow-y: hidden;
  margin: 34px -20px 24px;
  padding: 0 20px 24px;
  scrollbar-color: #87ceeb;

  &::after {
    content: "";
    min-width: 20px;
  }

  @media (min-width: 920px) {
    overflow-x: hidden;
    &::after {
      content: none;
    }
  }

  @media (min-width: 1150px) {
    margin-right: 0;
  }

  > * + * {
    margin-left: 20px;
  }
`;

export default Cards;
