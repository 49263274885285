import React from "react";

//React components imports
import Title from "../Title";
import Card from "../Card";

//Styled components imports
import Wrapper from "./Wrapper";
import Text from "../../shared-styled-components/Text";
import BoldText from "../../shared-styled-components/BoldText";
import Cards from "../../shared-styled-components/Cards";

const Products = () => {
  return (
    <Wrapper id="products">
      <Title>Produkty</Title>
      <Text>
        <BoldText>Středisko č. 1</BoldText> (Luděk Srb) nabízí vývoj a výrobu
        drobné mikroelektroniky.
        <br></br>
        <BoldText>Středisko č. 2</BoldText> (Luboš Srb) nabízí reklamní prostory
        na webových magazínech Mobilizujeme.cz, Elektrickevozy.cz,
        Inteligentnisvet.cz.
        <br></br>
        Propagujte produkt či službu právě u nás a oslovíte měsíčně přes milion
        čtenářů, které zajímají technologie všeho druhu!
      </Text>
      <Cards>
        <Card
          title="INDUKČNÍ VAŘIČ"
          titlePost=" PRO SENIORY"
          background="#fff"
          description="Nabízíme indukční vařič pro důchodce s úpravou pro maximálně jednoduché ovládání pouze jedním tlačítkem."
          link="/indukcni-varic"
          linkText="Zjistit více"
          type="product"
          linkType="local"
        />
        <Card
          flipped
          title="COIN TIMER"
          background="#fff"
          description="Nabízíme modul pro časové ovládání spotřebičů mincemi."
          link="/coin-timer"
          linkText="Zjistit více"
          type="product"
          linkType="local"
        />
        <Card
          title="INZERCE"
          background="#fff"
          description="Nabízíme například bannerovou reklamu či PR článek na našich technologických magazínech Mobilizujeme.cz, Elektrickevozy.cz, Inteligentnisvet.cz"
          link="mailto:lubos.srb@mobilizujeme.cz"
          linkText="Poptat inzerci"
          type="product"
          linkType="external"
        />
      </Cards>
    </Wrapper>
  );
};

export default Products;
