import styled from "styled-components";

const TitlePost = styled.span`
  font-size: 14px;

  @media (min-width: 1150px) {
    font-size: 18px;
  }
`;

export default TitlePost;
