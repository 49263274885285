import React from "react";

//Styled components imports
import Wrapper from "./Wrapper";
import Title from "./Title";
import TitlePost from "./TitlePost";
import Text from "./Text";
import LinkWrapper from "./LinkWrapper";
import Link from "./Link";
import GatsbyLink from "./GatsbyLink";

const Card = ({
  linkType,
  type,
  title,
  titlePost,
  description,
  background,
  flipped,
  link,
  linkText,
}) => {
  return (
    <Wrapper background={background} flipped={flipped} type={type}>
      <Title type={type}>
        {title}
        {titlePost ? <TitlePost>{titlePost}</TitlePost> : null}
      </Title>
      <Text type={type}>{description}</Text>
      <LinkWrapper>
        {linkType === "local" ? (
          <GatsbyLink
            to={link}
            target="_blank"
            rel="noreferrer"
            background={background}
            type={type}
          >
            {linkText ? linkText : "Přejít na stránku"}
          </GatsbyLink>
        ) : (
          <Link
            href={link}
            target="_blank"
            rel="noreferrer"
            background={background}
            type={type}
          >
            {linkText ? linkText : "Přejít na stránku"}
          </Link>
        )}
      </LinkWrapper>
    </Wrapper>
  );
};

export default Card;
