import styled from "styled-components"
import Img from "gatsby-image"

const Image = styled(Img)`
  max-width: ${props => (props.maxWidth ? `${props.maxWidth}px` : "505px")};
  margin: auto;
  display: block;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  margin-top: 34px;
`

export default Image
