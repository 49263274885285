import styled from "styled-components";

const LinkWrapper = styled.div`
  margin: auto;
  margin-bottom: 36px;

  @media (min-width: 1150px) {
    margin-bottom: 48px;
  }
`;

export default LinkWrapper;
