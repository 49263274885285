import styled from "styled-components";

const Title = styled.h3`
  font-weight: 500;
  font-size: 21px;
  color: ${props => (props.type === "product" ? "#3F3F3F" : "#fff")};
  padding: 21px 10px 18px;
  text-align: center;
  border-bottom: ${props =>
    props.type === "product" ? "1px solid #28B8B8" : "1px solid #ffffff"};

  @media (min-width: 1150px) {
    font-size: 28px;
    padding: 27px 10px 22px;
  }
`;

export default Title;
