import styled from "styled-components";
import { Link } from "gatsby";

const GatsbyLink = styled(Link)`
  padding: 6px 16px;
  background: ${props =>
    props.background
      ? props.background
      : "linear-gradient(270.72deg, #0de489 -2.9%, #07a8b3 102.01%)"};
  color: ${props => (props.type === "product" ? "#3F3F3F" : "#fff")};
  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 30px;
  font-weight: 600;

  @media (min-width: 1150px) {
    padding: 10px 32px;
  }

  &:hover {
    filter: ${props =>
      props.type === "product" ? "brightness(0.97)" : "brightness(1.06)"};
  }
`;

export default GatsbyLink;
