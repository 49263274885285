import React from "react"
import { useStaticQuery, graphql } from "gatsby"

//React components imports
import Title from "../Title"
import Card from "../Card"

//Styled components imports
import Wrapper from "./Wrapper"
import Text from "../../shared-styled-components/Text"
import BoldText from "../../shared-styled-components/BoldText"
import Cards from "../../shared-styled-components/Cards"
import Image from "./Image"
import Spacer from "../Spacer"

const About = () => {
  const image = useStaticQuery(graphql`
    query ImagesQuery {
      euImage: file(relativePath: { eq: "eu.png" }) {
        childImageSharp {
          fluid(maxWidth: 560) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      teslaImage: file(relativePath: { eq: "obr.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 720) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Wrapper id="about">
      <Title>O Firmě</Title>
      <Text>
        ArteMan s.r.o. je česká obchodní firma s technickým zaměřením. Je
        rozdělena do dvou středisek: <br></br>
        <BoldText>středisko č.1</BoldText> zastoupené Luďkem Srbem se zaměřujeme
        na servis a technické záležitosti.
        <br></br>
        <BoldText>středisko č.2</BoldText> zastoupené Lubošem Srbem se věnuje
        redakční, publikační a marketingové činnosti.
      </Text>
      <Cards>
        <Card
          title="MOBILIZUJEME.cz"
          flipped
          description="Přední český online magazín o mobilních telefonech a dalších chytrých zařízeních. Mobil, telefon, smartphone, tablet, chytré hodinky, příslušenství a mnoho dalšího."
          link="https://mobilizujeme.cz/"
          type="about"
          linkType="external"
        />
        <Card
          title="ELEKTRICKEVOZY.cz"
          description="Největší český online magazín o elektromobilitě, elektrifikaci dopravy a budoucnosti, kde technologie budou hrát zásadní roli. Elektromobil, elektroauto, hybrid, a další zkrátka elektrické vozy!"
          background="linear-gradient(90.77deg, #FF5F04 -0.33%, #FAC405 103.59%)"
          link="https://elektrickevozy.cz/"
          type="about"
          linkType="external"
        />
        <Card
          title="INTELIGENTNISVET.cz"
          flipped
          description="Nový online magazín o technologiích budoucnosti, ať už jde o umělou inteligenci, rozšířenou realitu, chytrou domácnost, či třeba vznik nové lidské rasy, která bude srůstat s výpočetní technikou."
          background="linear-gradient(90.68deg, #6620FA -0.62%, #9C51EA 100.44%)"
          link="https://inteligentnisvet.cz/"
          type="about"
          linkType="external"
        />
      </Cards>
      <Text>
        Firma ArteMan s.r.o. využívá firemní elektromobil, který je částečně
        spolufinancovaný z dotačního titulu Evropské unie.
      </Text>
      <Image fluid={image.euImage.childImageSharp.fluid} src="EU" alt="EU" />
      <Spacer size={48} axis="vertical" />
      <Text>
        Zúčastňujeme se veřejných aktivit a setkání, jak klubových akci Tesla,
        tak i společností zabývající se elektromobilitou a workshopů o
        elektromobilech. Často i setkání s odpůrci elektromobility. Součástí
        těchto setkání jsou i zkušební a předváděcí jízdy s velkým úspěchem
        změny negativních názorů na elektromobilitu.
        <br />
        <br />
        Název programu, ze kterého byl projekt podpořen: Operační program
        podnikání a inovace pro konkurenceschopnost, program podpory
        Nízkouhlíkové technologie, Výzva V.programu podpory Nízkouhlíkové
        technologie - Elektromobilita.
        <br />
        <br />
        <strong> Název projektu v rámci programu</strong> - Elektromobilita -
        ArteMan s.r.o.
        <br />
        <strong>Jméno žadatele</strong> - ArteMan s.r.o. <br />
        <strong>Termín realizace</strong> - 6. 1. 2020 - 17. 3. 2020
        <br />
        <br />
        <strong>Způsobilé výdaje </strong>- Pořízení 1 ks vozidla -
        elektromobilu v kategorii M1 - celkem 1 202 148,38,- Kč. Způsobilé
        výdaje se rovnají pořizovací ceně vozu bez nákladů na přepravu.
        <br />
        <br />
        <strong>Popis projektu</strong> - Projekt žadatele ArteMan s.r.o. v
        rámci V. Výzvy programu podpory NUT zavedl do činnosti podniku
        inovativní technologie (TRL 9) čisté mobility. V rámci způsobilých
        výdajů byl pořízen 1x Elektromobil pro vlastní potřebu. Projekt je
        realizován v Jihočeském kraji.
        <br />
        <br />
        Společnost ArteMan s.r.o. získala celkovou dotaci ve výši 360 644,51,-
        Kč.
      </Text>
      <Image
        maxWidth={720}
        fluid={image.teslaImage.childImageSharp.fluid}
        src="Tesla"
        alt="Tesla"
      />
    </Wrapper>
  )
}

export default About
