import styled from "styled-components";

const Text = styled.p`
  color: ${props => (props.type === "product" ? "#3F3F3F" : "#fff")};
  padding: 0 20px;
  margin-top: 16px;
  font-size: 16px;
  line-height: 24px;

  @media (min-width: 1150px) {
    padding: 0 20px;
    margin-top: 32px;
  }
`;

export default Text;
