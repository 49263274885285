import React from "react";

//React components imports
import Layout from "../components/Layout";
import About from "../components/About";
import Products from "../components/Products";

const HomePage = () => {
  return (
    <Layout>
      <About />
      <Products />
    </Layout>
  );
};

export default HomePage;
